/* tslint:disable */
/* eslint-disable */
/**
 * SBL API Glommen Mjøsen Skog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GmModelLibraryModelsAccountModel } from '../model';
/**
 * PropertyApi - axios parameter creator
 * @export
 */
export const PropertyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update property owner
         * @param {string} propertyId 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyUpdateProperty: async (propertyId: string, ownerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('propertyUpdateProperty', 'propertyId', propertyId)
            // verify required parameter 'ownerId' is not null or undefined
            assertParamExists('propertyUpdateProperty', 'ownerId', ownerId)
            const localVarPath = `/v1/properties/{propertyId}/owner/{ownerId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)))
                .replace(`{${"ownerId"}}`, encodeURIComponent(String(ownerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyApi - functional programming interface
 * @export
 */
export const PropertyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update property owner
         * @param {string} propertyId 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyUpdateProperty(propertyId: string, ownerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsAccountModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyUpdateProperty(propertyId, ownerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PropertyApi.propertyUpdateProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PropertyApi - factory interface
 * @export
 */
export const PropertyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyApiFp(configuration)
    return {
        /**
         * 
         * @summary Update property owner
         * @param {string} propertyId 
         * @param {string} ownerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyUpdateProperty(propertyId: string, ownerId: string, options?: any): AxiosPromise<GmModelLibraryModelsAccountModel> {
            return localVarFp.propertyUpdateProperty(propertyId, ownerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyApi - object-oriented interface
 * @export
 * @class PropertyApi
 * @extends {BaseAPI}
 */
export class PropertyApi extends BaseAPI {
    /**
     * 
     * @summary Update property owner
     * @param {string} propertyId 
     * @param {string} ownerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyApi
     */
    public propertyUpdateProperty(propertyId: string, ownerId: string, options?: RawAxiosRequestConfig) {
        return PropertyApiFp(this.configuration).propertyUpdateProperty(propertyId, ownerId, options).then((request) => request(this.axios, this.basePath));
    }
}

