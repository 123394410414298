/* tslint:disable */
/* eslint-disable */
/**
 * SBL API Glommen Mjøsen Skog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GmActionModelsModelsContractType } from '../model';
// @ts-ignore
import { GmModelLibraryModelsAllmaImageImagePreferences } from '../model';
// @ts-ignore
import { GmModelLibraryModelsAllmaJobsAllmaJobModel } from '../model';
// @ts-ignore
import { GmModelLibraryModelsAllmaJobsThematicMapTypeModel } from '../model';
/**
 * AllmaJobApi - axios parameter creator
 * @export
 */
export const AllmaJobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all Allma jobs that aren\'t used
         * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobGetAllUnusedAllmaJobs: async (contractTypeFilter?: GmActionModelsModelsContractType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/allmajobs/unused`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contractTypeFilter !== undefined) {
                localVarQueryParameter['contractTypeFilter'] = contractTypeFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Allma job by id
         * @param {string} id 
         * @param {boolean} [update] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobGetAllmaJob: async (id: string, update?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allmaJobGetAllmaJob', 'id', id)
            const localVarPath = `/v1/allmajobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (update !== undefined) {
                localVarQueryParameter['update'] = update;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Allma jobs by property
         * @param {string} propertyId 
         * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobGetAllmaJobsByProperty: async (propertyId: string, contractTypeFilter?: GmActionModelsModelsContractType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyId' is not null or undefined
            assertParamExists('allmaJobGetAllmaJobsByProperty', 'propertyId', propertyId)
            const localVarPath = `/v1/allmajobs/properties/{propertyId}`
                .replace(`{${"propertyId"}}`, encodeURIComponent(String(propertyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contractTypeFilter !== undefined) {
                localVarQueryParameter['contractTypeFilter'] = contractTypeFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get image from Allma
         * @param {GmModelLibraryModelsAllmaImageImagePreferences} [gmModelLibraryModelsAllmaImageImagePreferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobGetImage: async (gmModelLibraryModelsAllmaImageImagePreferences?: GmModelLibraryModelsAllmaImageImagePreferences, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/allmajobs/getImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmModelLibraryModelsAllmaImageImagePreferences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all own Allma jobs that aren\'t used
         * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobGetOwnAllmaJobs: async (contractTypeFilter?: GmActionModelsModelsContractType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/allmajobs/own`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contractTypeFilter !== undefined) {
                localVarQueryParameter['contractTypeFilter'] = contractTypeFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List thematic map types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobListThematicMapTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/allmajobs/thematicMapTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobTestContact: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/allmajobs/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllmaJobApi - functional programming interface
 * @export
 */
export const AllmaJobApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllmaJobApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all Allma jobs that aren\'t used
         * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allmaJobGetAllUnusedAllmaJobs(contractTypeFilter?: GmActionModelsModelsContractType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsAllmaJobsAllmaJobModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allmaJobGetAllUnusedAllmaJobs(contractTypeFilter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AllmaJobApi.allmaJobGetAllUnusedAllmaJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Allma job by id
         * @param {string} id 
         * @param {boolean} [update] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allmaJobGetAllmaJob(id: string, update?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsAllmaJobsAllmaJobModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allmaJobGetAllmaJob(id, update, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AllmaJobApi.allmaJobGetAllmaJob']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get Allma jobs by property
         * @param {string} propertyId 
         * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allmaJobGetAllmaJobsByProperty(propertyId: string, contractTypeFilter?: GmActionModelsModelsContractType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsAllmaJobsAllmaJobModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allmaJobGetAllmaJobsByProperty(propertyId, contractTypeFilter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AllmaJobApi.allmaJobGetAllmaJobsByProperty']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get image from Allma
         * @param {GmModelLibraryModelsAllmaImageImagePreferences} [gmModelLibraryModelsAllmaImageImagePreferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allmaJobGetImage(gmModelLibraryModelsAllmaImageImagePreferences?: GmModelLibraryModelsAllmaImageImagePreferences, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allmaJobGetImage(gmModelLibraryModelsAllmaImageImagePreferences, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AllmaJobApi.allmaJobGetImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all own Allma jobs that aren\'t used
         * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allmaJobGetOwnAllmaJobs(contractTypeFilter?: GmActionModelsModelsContractType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsAllmaJobsAllmaJobModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allmaJobGetOwnAllmaJobs(contractTypeFilter, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AllmaJobApi.allmaJobGetOwnAllmaJobs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary List thematic map types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allmaJobListThematicMapTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsAllmaJobsThematicMapTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allmaJobListThematicMapTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AllmaJobApi.allmaJobListThematicMapTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary ?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allmaJobTestContact(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allmaJobTestContact(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AllmaJobApi.allmaJobTestContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AllmaJobApi - factory interface
 * @export
 */
export const AllmaJobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllmaJobApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all Allma jobs that aren\'t used
         * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobGetAllUnusedAllmaJobs(contractTypeFilter?: GmActionModelsModelsContractType, options?: any): AxiosPromise<Array<GmModelLibraryModelsAllmaJobsAllmaJobModel>> {
            return localVarFp.allmaJobGetAllUnusedAllmaJobs(contractTypeFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Allma job by id
         * @param {string} id 
         * @param {boolean} [update] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobGetAllmaJob(id: string, update?: boolean, options?: any): AxiosPromise<GmModelLibraryModelsAllmaJobsAllmaJobModel> {
            return localVarFp.allmaJobGetAllmaJob(id, update, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Allma jobs by property
         * @param {string} propertyId 
         * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobGetAllmaJobsByProperty(propertyId: string, contractTypeFilter?: GmActionModelsModelsContractType, options?: any): AxiosPromise<Array<GmModelLibraryModelsAllmaJobsAllmaJobModel>> {
            return localVarFp.allmaJobGetAllmaJobsByProperty(propertyId, contractTypeFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get image from Allma
         * @param {GmModelLibraryModelsAllmaImageImagePreferences} [gmModelLibraryModelsAllmaImageImagePreferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobGetImage(gmModelLibraryModelsAllmaImageImagePreferences?: GmModelLibraryModelsAllmaImageImagePreferences, options?: any): AxiosPromise<string> {
            return localVarFp.allmaJobGetImage(gmModelLibraryModelsAllmaImageImagePreferences, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all own Allma jobs that aren\'t used
         * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobGetOwnAllmaJobs(contractTypeFilter?: GmActionModelsModelsContractType, options?: any): AxiosPromise<Array<GmModelLibraryModelsAllmaJobsAllmaJobModel>> {
            return localVarFp.allmaJobGetOwnAllmaJobs(contractTypeFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List thematic map types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobListThematicMapTypes(options?: any): AxiosPromise<Array<GmModelLibraryModelsAllmaJobsThematicMapTypeModel>> {
            return localVarFp.allmaJobListThematicMapTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allmaJobTestContact(options?: any): AxiosPromise<string> {
            return localVarFp.allmaJobTestContact(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllmaJobApi - object-oriented interface
 * @export
 * @class AllmaJobApi
 * @extends {BaseAPI}
 */
export class AllmaJobApi extends BaseAPI {
    /**
     * 
     * @summary Get all Allma jobs that aren\'t used
     * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllmaJobApi
     */
    public allmaJobGetAllUnusedAllmaJobs(contractTypeFilter?: GmActionModelsModelsContractType, options?: RawAxiosRequestConfig) {
        return AllmaJobApiFp(this.configuration).allmaJobGetAllUnusedAllmaJobs(contractTypeFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Allma job by id
     * @param {string} id 
     * @param {boolean} [update] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllmaJobApi
     */
    public allmaJobGetAllmaJob(id: string, update?: boolean, options?: RawAxiosRequestConfig) {
        return AllmaJobApiFp(this.configuration).allmaJobGetAllmaJob(id, update, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Allma jobs by property
     * @param {string} propertyId 
     * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllmaJobApi
     */
    public allmaJobGetAllmaJobsByProperty(propertyId: string, contractTypeFilter?: GmActionModelsModelsContractType, options?: RawAxiosRequestConfig) {
        return AllmaJobApiFp(this.configuration).allmaJobGetAllmaJobsByProperty(propertyId, contractTypeFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get image from Allma
     * @param {GmModelLibraryModelsAllmaImageImagePreferences} [gmModelLibraryModelsAllmaImageImagePreferences] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllmaJobApi
     */
    public allmaJobGetImage(gmModelLibraryModelsAllmaImageImagePreferences?: GmModelLibraryModelsAllmaImageImagePreferences, options?: RawAxiosRequestConfig) {
        return AllmaJobApiFp(this.configuration).allmaJobGetImage(gmModelLibraryModelsAllmaImageImagePreferences, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all own Allma jobs that aren\'t used
     * @param {GmActionModelsModelsContractType} [contractTypeFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllmaJobApi
     */
    public allmaJobGetOwnAllmaJobs(contractTypeFilter?: GmActionModelsModelsContractType, options?: RawAxiosRequestConfig) {
        return AllmaJobApiFp(this.configuration).allmaJobGetOwnAllmaJobs(contractTypeFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List thematic map types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllmaJobApi
     */
    public allmaJobListThematicMapTypes(options?: RawAxiosRequestConfig) {
        return AllmaJobApiFp(this.configuration).allmaJobListThematicMapTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ?
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllmaJobApi
     */
    public allmaJobTestContact(options?: RawAxiosRequestConfig) {
        return AllmaJobApiFp(this.configuration).allmaJobTestContact(options).then((request) => request(this.axios, this.basePath));
    }
}

