import revive_payload_client_bXn7FieKiq9fq6_QhqaNXY_3B0_BG1nQIjPEs2VIZG8 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_d4d3afbadaff945ee90933a7dd38a826/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0H4lhSdi6_MHuOf1ZLovWk1Lk_Eke6e2qNwrT5zpGb0 from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_d4d3afbadaff945ee90933a7dd38a826/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MizGlW76W8pu8d2gYAtlhjiZG7C2H5bFSTxmZ_Pbapc from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_d4d3afbadaff945ee90933a7dd38a826/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0J2P_6odUssKWWcK6hlE88so_D0jOIqJgW2PgGnr1_Q from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_d4d3afbadaff945ee90933a7dd38a826/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2_AKGjhjDoENIcEeiSL6dnBM9QDuJQw_dYgFt6vGbNo from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_d4d3afbadaff945ee90933a7dd38a826/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IJ2KkrAq_c_Pypxws_VurmYgVkmbAzkJbaezRnH6qSI from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_d4d3afbadaff945ee90933a7dd38a826/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jJ_hknffyC__nyFZ2qG8uiTcn3VgajeF0xD4WG52Z6c from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_d4d3afbadaff945ee90933a7dd38a826/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/vsts/work/1/s/.nuxt/components.plugin.mjs";
import prefetch_client_VhyvcgGvgqdCTakvn2d4t6NGLCvDpJzz1PhhfV_gKko from "/home/vsts/work/1/s/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_db0@0.3.1_eslint@9.22.0_jiti@2.4_d4d3afbadaff945ee90933a7dd38a826/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/home/vsts/work/1/s/.nuxt/pwa-icons-plugin.ts";
import pwa_client_c0ieEuM79orYszN1eKhj3b1ZXXghifuL7bCaJ2vuegs from "/home/vsts/work/1/s/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_vite@6.2.1_@types+node@22.13.10_jiti@2.4.2_terser@_12ee2a08485d621d0c7354e20438473a/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import switch_locale_path_ssr_9S7dfRTouVKlz0d3os_GWHD0D6Jcqvsejtpm6udlfXc from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_ZLylsD3GeOw6FEw9RjZNOGRWhOQhJitHZEg1TqX1kbU from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_jGivfSwNLf4wM1F1PenDrV_dktr72SDsmBwqkRF91Z4 from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _1_application_insights_client_tL4YJt6VVoQtjS4Kaevat1qU_c90Te2mCYcoAS0kekM from "/home/vsts/work/1/s/plugins/1.application-insights.client.ts";
import _2_auth_NBvrvpVDh_fIrwxVbwY3K8_Kueg_yZubG7FJc7aK7l0 from "/home/vsts/work/1/s/plugins/2.auth.ts";
import _3_axios_ouUI00d9ZvZWV_veII1YtnJWL3FePZjOSpOCJyj3snY from "/home/vsts/work/1/s/plugins/3.axios.ts";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/home/vsts/work/1/s/plugins/error-handler.ts";
import nuxt_link_import_OPgHYIKvqx5v3udHRqMHT8iNYJ76pAMyxr64FQ6pdgU from "/home/vsts/work/1/s/plugins/nuxt-link-import.ts";
import pwa_client_FJwzeegYDpFhjXgvIvQslGKRerMPgQavqBBhIXjOHyY from "/home/vsts/work/1/s/plugins/pwa.client.ts";
import router_watch_njbOXVQriST00HTV_3ta_KYuf_d_EeIAtI0pn1tRJUY from "/home/vsts/work/1/s/plugins/router-watch.ts";
import sentry_logging_2hQRb03gAtoEIqAcb6pKNXkJNnnGZwm88zGTpsUVueM from "/home/vsts/work/1/s/plugins/sentry-logging.ts";
import vue_pdf_8cmat6ENd30Nvgysqm7enJl93MPfR87_uCQ9sRFNZgY from "/home/vsts/work/1/s/plugins/vue-pdf.ts";
import vue_router_append_1JqSbL2axSjRTHsjeuMQRjqqHRWO1Y9WeuF3ik78X8Y from "/home/vsts/work/1/s/plugins/vue-router-append.ts";
import vue_signature_pad_5fxhB_JMiX_WYQd3BBgLe5rRbPNCs_TVj7kyTwJGR_c from "/home/vsts/work/1/s/plugins/vue-signature-pad.ts";
import ssg_detect_e2iIdw4Yiz_Ejyw46xAdZlX86afzK5shtUtXxQQD2zI from "/home/vsts/work/1/s/node_modules/.pnpm/@nuxtjs+i18n@9.3.1_@vue+compiler-dom@3.5.13_eslint@9.22.0_jiti@2.4.2__magicast@0.3.5_ro_641333078fa90b83425ed1c5e7544709/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_bXn7FieKiq9fq6_QhqaNXY_3B0_BG1nQIjPEs2VIZG8,
  unhead_0H4lhSdi6_MHuOf1ZLovWk1Lk_Eke6e2qNwrT5zpGb0,
  router_MizGlW76W8pu8d2gYAtlhjiZG7C2H5bFSTxmZ_Pbapc,
  payload_client_0J2P_6odUssKWWcK6hlE88so_D0jOIqJgW2PgGnr1_Q,
  navigation_repaint_client_2_AKGjhjDoENIcEeiSL6dnBM9QDuJQw_dYgFt6vGbNo,
  check_outdated_build_client_IJ2KkrAq_c_Pypxws_VurmYgVkmbAzkJbaezRnH6qSI,
  chunk_reload_client_jJ_hknffyC__nyFZ2qG8uiTcn3VgajeF0xD4WG52Z6c,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_VhyvcgGvgqdCTakvn2d4t6NGLCvDpJzz1PhhfV_gKko,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_c0ieEuM79orYszN1eKhj3b1ZXXghifuL7bCaJ2vuegs,
  switch_locale_path_ssr_9S7dfRTouVKlz0d3os_GWHD0D6Jcqvsejtpm6udlfXc,
  route_locale_detect_ZLylsD3GeOw6FEw9RjZNOGRWhOQhJitHZEg1TqX1kbU,
  i18n_jGivfSwNLf4wM1F1PenDrV_dktr72SDsmBwqkRF91Z4,
  _1_application_insights_client_tL4YJt6VVoQtjS4Kaevat1qU_c90Te2mCYcoAS0kekM,
  _2_auth_NBvrvpVDh_fIrwxVbwY3K8_Kueg_yZubG7FJc7aK7l0,
  _3_axios_ouUI00d9ZvZWV_veII1YtnJWL3FePZjOSpOCJyj3snY,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  nuxt_link_import_OPgHYIKvqx5v3udHRqMHT8iNYJ76pAMyxr64FQ6pdgU,
  pwa_client_FJwzeegYDpFhjXgvIvQslGKRerMPgQavqBBhIXjOHyY,
  router_watch_njbOXVQriST00HTV_3ta_KYuf_d_EeIAtI0pn1tRJUY,
  sentry_logging_2hQRb03gAtoEIqAcb6pKNXkJNnnGZwm88zGTpsUVueM,
  vue_pdf_8cmat6ENd30Nvgysqm7enJl93MPfR87_uCQ9sRFNZgY,
  vue_router_append_1JqSbL2axSjRTHsjeuMQRjqqHRWO1Y9WeuF3ik78X8Y,
  vue_signature_pad_5fxhB_JMiX_WYQd3BBgLe5rRbPNCs_TVj7kyTwJGR_c,
  ssg_detect_e2iIdw4Yiz_Ejyw46xAdZlX86afzK5shtUtXxQQD2zI
]