import cloneDeep from 'lodash/cloneDeep'
import { generateUUID } from '../uuid/util'
import { useCustomersDb } from '../indexedDb/customersDb'
import {
  CONTRACT_COMPLETIONS,
  CONTRACT_TYPE_REGISTRATION,
  CONTRACT_TYPE_SILVICULTURE_AGREEMENT,
  CONTRACT_TYPE_STEPS,
  CONTRACT_TYPE_SUPPLY_WOOD,
  CONTRACT_TYPES
} from './constants'
import { CUSTOMER_SELF_CONTRACTOR_ID } from './volumeAndContractor/constants'
import { setCalculatedYearMonthAndPeriod } from './deliveryDates/util'
import type {User} from '~/models/auth'
import type {Contract, ContractTypeStep, FullContract} from '~/models/contract'
import type {Customer, Property} from '~/models/customers'
import { CONTRACT_STATES, CONTRACT_STATES_DRAFT } from '~/constants/contract'

export const getContractTypeLabel = (type: number): string => {
  return CONTRACT_TYPES.find((s) => s.Id === type)
    ? CONTRACT_TYPES.find((s) => s.Id === type)?.Name || ''
    : ''
}

export const getContractTypeSteps = (type: number): ContractTypeStep[] => {
  return CONTRACT_TYPE_STEPS.find(s => s.Type === type) ? CONTRACT_TYPE_STEPS.find(s => s.Type === type)?.Steps || [] : [] as ContractTypeStep[]
}

export const getDefaultContractData = (type: number, customer: Customer, property: Property, manager: User): FullContract => {
  let contract: FullContract = {
    Id: 'temp_' + generateUUID(),
    Type: type,
    Status: CONTRACT_STATES_DRAFT,
    Pefc: true,
    CertificationType: !property.CocCode ? 1 : 9,
    PropertyId: property.Id,
    ForestFundRate: 15,
    ForestOwnerId: customer.Id,
    // PropertyOwnerId: property.PropertyOwner ? property.PropertyOwner.Id : null,
    Year: new Date().getFullYear(),
    LoggingMonth: 0,
    Period: 0,
    SilviculturePeriod: 0,
    SilvicultureYear: 0,
    RoadOperationContact: customer.Name,
    RoadOperationPhone: customer.Mobile,
    EnvironmentalReportDueDilligence: true,
    AssortmentInformation: {
      EntrepreneurCapacity: 0,
      EntrepreneurId: type === CONTRACT_TYPE_SUPPLY_WOOD && !customer.IsLargeForestOwner ? CUSTOMER_SELF_CONTRACTOR_ID : null,
      TotalVolume: 0,
      TotalWoodForSale: 0,
      SumVolume: 0,
      WoodForOwnUseVolume: 0
    },
    Sivilculture: {
      ForestOwnerTransportsPlants: false
    },
    Environment: {},
    AgreementDetails: {
      OperationCostType: 1
    },
    Completition: {},
    ActionPlans: [],
    TopplePlaces: [],
    Details: [],
    MapImages: [],
    Meta: {}
  }

  if (type === CONTRACT_TYPE_SILVICULTURE_AGREEMENT) {
    contract = setCalculatedYearMonthAndPeriod(contract)
    contract.EnvironmentReport = {
      Environments: {},
      Planning: {}
    }
  } else if (type === CONTRACT_TYPE_REGISTRATION) {
    contract.AgreementDetails!.OperationCostType = 3 // Manual
    contract.AgreementDetails!.OperationPrice = 0
  } else if (type === CONTRACT_TYPE_SUPPLY_WOOD) {
    contract.Environment!.EnvironmentResponsibleName = customer.LScourse ? customer.Name : ''
    contract.Environment!.EnvironmentResponsibleEmail = customer.LScourse ? customer.Email : ''
    contract.EnvironmentReport = {
      Environments: {},
      Planning: {}
    }
  } else {
    contract.EnvironmentReport = {
      Environments: {},
      Planning: {}
    }
  }

  return contract
}

export const getTotalVolumeFromActions = (contract: FullContract): number => {
  const activeActionPlans = contract.ActionPlans?.filter(x => !x.Deleted) || []
  const activeAllmaJobs = contract.AllmaJobs?.filter(x => !x.Removed) || []

  if (activeAllmaJobs.length > 0) {
    return activeAllmaJobs.reduce((prev, cur) => {
      return prev + cur.Actions?.reduce((p, c) => c.TotalVolume && !c.AdditionalOrder ? p + c.TotalVolume : p, 0)
    }, 0)
  } else {
    return activeActionPlans.reduce((prev, cur) => cur.TotalVolume && !cur.AdditionalOrder ? prev + cur.TotalVolume : prev + 0, 0)
  }
}

export const getContractStateLabel = (contract: Contract): string => CONTRACT_STATES.find(s => s.Id === contract.Status) ? CONTRACT_STATES.find(s => s.Id === contract.Status).Name : ''

export const getContractCompletionLabel = (contract: FullContract): string =>
  CONTRACT_COMPLETIONS.find(s => s.Id === contract.AssortmentInformation!.CompleteOperationTarget) ? CONTRACT_COMPLETIONS.find(s => s.Id === contract.AssortmentInformation?.CompleteOperationTarget)?.Name : ''

export const isDraft = (contract: Contract | FullContract): boolean => !contract.Id || contract?.Id?.includes('temp_')

export const cleanDraftContract = (contract: FullContract): FullContract => {
  const tmpContract = cloneDeep(contract)
  if (tmpContract.Id?.includes('temp_')) {
    tmpContract.TempId = tmpContract.Id
    delete tmpContract.Id
  }

  tmpContract.ActionPlans?.forEach((actionPlan) => {
    if (actionPlan.Id?.includes('temp_')) {
      delete actionPlan.Id
    }
  })

  tmpContract.TopplePlaces?.forEach((topplePlace) => {
    if (topplePlace.Id?.includes('temp_')) {
      delete topplePlace.Id
    }
  })

  tmpContract.Details?.forEach((detail) => {
    if (detail.Id?.includes('temp_')) {
      delete detail.Id
    }
  })

  tmpContract.MapImages?.forEach((image) => {
    if (image.Id?.includes('temp_')) {
      delete image.Id
    }
  })

  return tmpContract
}

export const contractToFull = (contract: Contract): FullContract => ({
  Id: contract.Id,
  Name: contract.Name,
  ContractNo: contract.ContractNo,
  VsysNo: contract.VsysNo,
  Type: contract.Type,
  SignedOn: contract.SignedOn,
  CreatedDate: contract.CreatedDate,
  ModifiedDate: contract.ModifiedDate,
  Status: contract.Status,
  ForestOwnerId: contract.ForestOwnerId,
  PropertyId: contract.PropertyId,
  AssortmentInformation: {
    EntrepreneurId: contract.EntrepreneurTeamId,
    SumVolume: contract.Volume
  },
  VolumeProduced: contract.VolumeProduced,
  VolumeTimberLot: contract.VolumeTimberLot,
  VolumeDelivery: contract.VolumeDelivery,
  VolumeMeasured: contract.VolumeMeasured,
  Year: contract.Year,
  LoggingMonth: contract.LoggingMonth
})

export const getContractCustomer = async (contract: Contract | FullContract): Promise<Customer | undefined> => {
  if (!contract.ForestOwnerId) {
    return {}
  }
  try {
    const { customersDb } = useCustomersDb()
    return await customersDb.getById(contract.ForestOwnerId)
  } catch (error) {
    console.error(error)
  }
}
