/* tslint:disable */
/* eslint-disable */
/**
 * SBL API Glommen Mjøsen Skog
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GmModelLibraryModelsAccountModel } from '../model';
/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get customer info
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomerV2: async (customerId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerGetCustomerV2', 'customerId', customerId)
            const localVarPath = `/v1/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customers by current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomersV2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update customer
         * @param {string} customerId 
         * @param {GmModelLibraryModelsAccountModel} [gmModelLibraryModelsAccountModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateCustomer: async (customerId: string, gmModelLibraryModelsAccountModel?: GmModelLibraryModelsAccountModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerUpdateCustomer', 'customerId', customerId)
            const localVarPath = `/v1/customers/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmModelLibraryModelsAccountModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get customer info
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetCustomerV2(customerId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsAccountModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetCustomerV2(customerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.customerGetCustomerV2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get customers by current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerGetCustomersV2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsAccountModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerGetCustomersV2(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.customerGetCustomersV2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update customer
         * @param {string} customerId 
         * @param {GmModelLibraryModelsAccountModel} [gmModelLibraryModelsAccountModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerUpdateCustomer(customerId: string, gmModelLibraryModelsAccountModel?: GmModelLibraryModelsAccountModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsAccountModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerUpdateCustomer(customerId, gmModelLibraryModelsAccountModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerApi.customerUpdateCustomer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get customer info
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomerV2(customerId: string, options?: any): AxiosPromise<Array<GmModelLibraryModelsAccountModel>> {
            return localVarFp.customerGetCustomerV2(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customers by current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerGetCustomersV2(options?: any): AxiosPromise<Array<GmModelLibraryModelsAccountModel>> {
            return localVarFp.customerGetCustomersV2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update customer
         * @param {string} customerId 
         * @param {GmModelLibraryModelsAccountModel} [gmModelLibraryModelsAccountModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerUpdateCustomer(customerId: string, gmModelLibraryModelsAccountModel?: GmModelLibraryModelsAccountModel, options?: any): AxiosPromise<GmModelLibraryModelsAccountModel> {
            return localVarFp.customerUpdateCustomer(customerId, gmModelLibraryModelsAccountModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Get customer info
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerGetCustomerV2(customerId: string, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).customerGetCustomerV2(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customers by current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerGetCustomersV2(options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).customerGetCustomersV2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update customer
     * @param {string} customerId 
     * @param {GmModelLibraryModelsAccountModel} [gmModelLibraryModelsAccountModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customerUpdateCustomer(customerId: string, gmModelLibraryModelsAccountModel?: GmModelLibraryModelsAccountModel, options?: RawAxiosRequestConfig) {
        return CustomerApiFp(this.configuration).customerUpdateCustomer(customerId, gmModelLibraryModelsAccountModel, options).then((request) => request(this.axios, this.basePath));
    }
}

